div.filter-field[data-source='status'] {
    align-items: center;
    flex-direction: row-reverse;
}

.ml-2 {
    margin-left: 2rem !important;
}

.boolean-tick svg[data-testid='false'] path {
    fill: red;
}

.boolean-tick svg[data-testid='true'] path {
    fill: green;
}

.menu {
    display: flex;
    margin-top: 0.5em;
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    margin-bottom: 1em;
    flex-direction: column;
    justify-content: flex-start;
}

.menu-closed {
    width: 55px;
}

.menu-opened {
    width: 240px;
}
